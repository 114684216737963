define(["dojo-proxy-loader?name=dojo/query!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/home/mcdeploy/mc_node_modules_cache/8a2ad5ea804ae1302cda89c2c979651c70454223/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module"], function (dquery, lang, array) {
  // module:
  //		dojo/NodeList-traverse

  /*=====
  return function(){
  	// summary:
  	//		Adds chainable methods to dojo/query() / NodeList instances for traversing the DOM
  };
  =====*/

  var NodeList = dquery.NodeList;
  lang.extend(NodeList, {
    _buildArrayFromCallback: function ( /*Function*/callback) {
      // summary:
      //		builds a new array of possibly differing size based on the input list.
      //		Since the returned array is likely of different size than the input array,
      //		the array's map function cannot be used.
      var ary = [];
      for (var i = 0; i < this.length; i++) {
        var items = callback.call(this[i], this[i], ary);
        if (items) {
          ary = ary.concat(items);
        }
      }
      return ary; //Array
    },
    _getUniqueAsNodeList: function ( /*Array*/nodes) {
      // summary:
      //		given a list of nodes, make sure only unique
      //		elements are returned as our NodeList object.
      //		Does not call _stash().
      var ary = [];
      //Using for loop for better speed.
      for (var i = 0, node; node = nodes[i]; i++) {
        //Should be a faster way to do this. dojo/query has a private
        //_zip function that may be inspirational, but there are pathways
        //in query that force nozip?
        if (node.nodeType == 1 && array.indexOf(ary, node) == -1) {
          ary.push(node);
        }
      }
      return this._wrap(ary, null, this._NodeListCtor); // dojo/NodeList
    },
    _getUniqueNodeListWithParent: function ( /*Array*/nodes, /*String*/query) {
      // summary:
      //		gets unique element nodes, filters them further
      //		with an optional query and then calls _stash to track parent NodeList.
      var ary = this._getUniqueAsNodeList(nodes);
      ary = query ? dquery._filterResult(ary, query) : ary;
      return ary._stash(this); // dojo/NodeList
    },
    _getRelatedUniqueNodes: function ( /*String?*/query, /*Function*/callback) {
      // summary:
      //		cycles over all the nodes and calls a callback
      //		to collect nodes for a possible inclusion in a result.
      //		The callback will get two args: callback(node, ary),
      //		where ary is the array being used to collect the nodes.
      return this._getUniqueNodeListWithParent(this._buildArrayFromCallback(callback), query); // dojo/NodeList
    },
    children: function ( /*String?*/query) {
      // summary:
      //		Returns all immediate child elements for nodes in this dojo/NodeList.
      //		Optionally takes a query to filter the child elements.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // query:
      //		a CSS selector.
      // returns:
      //		all immediate child elements for the nodes in this dojo/NodeList.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red">Red One</div>
      // 	|		Some Text
      // 	|		<div class="blue">Blue One</div>
      // 	|		<div class="red">Red Two</div>
      // 	|		<div class="blue">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".container").children();
      //	|	});
      //		returns the four divs that are children of the container div.
      //		Running this code:
      //	|	dojo.query(".container").children(".red");
      //		returns the two divs that have the class "red".
      return this._getRelatedUniqueNodes(query, function (node, ary) {
        return lang._toArray(node.childNodes);
      }); // dojo/NodeList
    },
    closest: function ( /*String*/query, /*String|DOMNode?*/root) {
      // summary:
      //		Returns closest parent that matches query, including current node in this
      //		dojo/NodeList if it matches the query.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // query:
      //		a CSS selector.
      // root:
      //		If specified, query is relative to "root" rather than document body.
      // returns:
      //		the closest parent that matches the query, including the current
      //		node in this dojo/NodeList if it matches the query.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      //	|		<div class="red">Red One</div>
      //	|		Some Text
      //	|		<div class="blue">Blue One</div>
      //	|		<div class="red">Red Two</div>
      //	|		<div class="blue">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".red").closest(".container");
      //	|	});
      //		returns the div with class "container".
      return this._getRelatedUniqueNodes(null, function (node, ary) {
        do {
          if (dquery._filterResult([node], query, root).length) {
            return node;
          }
        } while (node != root && (node = node.parentNode) && node.nodeType == 1);
        return null; //To make rhino strict checking happy.
      }); // dojo/NodeList
    },
    parent: function ( /*String?*/query) {
      // summary:
      //		Returns immediate parent elements for nodes in this dojo/NodeList.
      //		Optionally takes a query to filter the parent elements.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // query:
      //		a CSS selector.
      // returns:
      //		immediate parent elements for nodes in this dojo/NodeList.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red">Red One</div>
      // 	|		<div class="blue first"><span class="text">Blue One</span></div>
      // 	|		<div class="red">Red Two</div>
      // 	|		<div class="blue"><span class="text">Blue Two</span></div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".text").parent();
      //	|	});
      //		returns the two divs with class "blue".
      //		Running this code:
      //	|		query(".text").parent(".first");
      //		returns the one div with class "blue" and "first".
      return this._getRelatedUniqueNodes(query, function (node, ary) {
        return node.parentNode;
      }); // dojo/NodeList
    },
    parents: function ( /*String?*/query) {
      // summary:
      //		Returns all parent elements for nodes in this dojo/NodeList.
      //		Optionally takes a query to filter the child elements.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // query:
      //		a CSS selector.
      // returns:
      //		all parent elements for nodes in this dojo/NodeList.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red">Red One</div>
      // 	|		<div class="blue first"><span class="text">Blue One</span></div>
      // 	|		<div class="red">Red Two</div>
      // 	|		<div class="blue"><span class="text">Blue Two</span></div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".text").parents();
      //	|	});
      //		returns the two divs with class "blue", the div with class "container",
      // 	|	the body element and the html element.
      //		Running this code:
      //	|		query(".text").parents(".container");
      //		returns the one div with class "container".
      return this._getRelatedUniqueNodes(query, function (node, ary) {
        var pary = [];
        while (node.parentNode) {
          node = node.parentNode;
          pary.push(node);
        }
        return pary;
      }); // dojo/NodeList
    },
    siblings: function ( /*String?*/query) {
      // summary:
      //		Returns all sibling elements for nodes in this dojo/NodeList.
      //		Optionally takes a query to filter the sibling elements.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // query:
      //		a CSS selector.
      // returns:
      //		all sibling elements for nodes in this dojo/NodeList.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red">Red One</div>
      // 	|		Some Text
      // 	|		<div class="blue first">Blue One</div>
      // 	|		<div class="red">Red Two</div>
      // 	|		<div class="blue">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".first").siblings();
      //	|	});
      //		returns the two divs with class "red" and the other div
      // 	|	with class "blue" that does not have "first".
      //		Running this code:
      //	|		query(".first").siblings(".red");
      //		returns the two div with class "red".
      return this._getRelatedUniqueNodes(query, function (node, ary) {
        var pary = [];
        var nodes = node.parentNode && node.parentNode.childNodes;
        for (var i = 0; i < nodes.length; i++) {
          if (nodes[i] != node) {
            pary.push(nodes[i]);
          }
        }
        return pary;
      }); // dojo/NodeList
    },
    next: function ( /*String?*/query) {
      // summary:
      //		Returns the next element for nodes in this dojo/NodeList.
      //		Optionally takes a query to filter the next elements.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // query:
      //		a CSS selector.
      // returns:
      //		the next element for nodes in this dojo/NodeList.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red">Red One</div>
      // 	|		Some Text
      // 	|		<div class="blue first">Blue One</div>
      // 	|		<div class="red">Red Two</div>
      // 	|		<div class="blue last">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".first").next();
      //	|	});
      //		returns the div with class "red" and has innerHTML of "Red Two".
      //		Running this code:
      //	|	dojo.query(".last").next(".red");
      //		does not return any elements.
      return this._getRelatedUniqueNodes(query, function (node, ary) {
        var next = node.nextSibling;
        while (next && next.nodeType != 1) {
          next = next.nextSibling;
        }
        return next;
      }); // dojo/NodeList
    },
    nextAll: function ( /*String?*/query) {
      // summary:
      //		Returns all sibling elements that come after the nodes in this dojo/NodeList.
      //		Optionally takes a query to filter the sibling elements.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // query:
      //		a CSS selector.
      // returns:
      //		all sibling elements that come after the nodes in this dojo/NodeList.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red">Red One</div>
      // 	|		Some Text
      // 	|		<div class="blue first">Blue One</div>
      // 	|		<div class="red next">Red Two</div>
      // 	|		<div class="blue next">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".first").nextAll();
      //	|	});
      //		returns the two divs with class of "next".
      //		Running this code:
      //	|		query(".first").nextAll(".red");
      //		returns the one div with class "red" and innerHTML "Red Two".
      return this._getRelatedUniqueNodes(query, function (node, ary) {
        var pary = [];
        var next = node;
        while (next = next.nextSibling) {
          if (next.nodeType == 1) {
            pary.push(next);
          }
        }
        return pary;
      }); // dojo/NodeList
    },
    prev: function ( /*String?*/query) {
      // summary:
      //		Returns the previous element for nodes in this dojo/NodeList.
      //		Optionally takes a query to filter the previous elements.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // query:
      //		a CSS selector.
      // returns:
      //		the previous element for nodes in this dojo/NodeList.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red">Red One</div>
      // 	|		Some Text
      // 	|		<div class="blue first">Blue One</div>
      // 	|		<div class="red">Red Two</div>
      // 	|		<div class="blue">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".first").prev();
      //	|	});
      //		returns the div with class "red" and has innerHTML of "Red One".
      //		Running this code:
      //	|		query(".first").prev(".blue");
      //		does not return any elements.
      return this._getRelatedUniqueNodes(query, function (node, ary) {
        var prev = node.previousSibling;
        while (prev && prev.nodeType != 1) {
          prev = prev.previousSibling;
        }
        return prev;
      }); // dojo/NodeList
    },
    prevAll: function ( /*String?*/query) {
      // summary:
      //		Returns all sibling elements that come before the nodes in this dojo/NodeList.
      //		Optionally takes a query to filter the sibling elements.
      // description:
      //		The returned nodes will be in reverse DOM order -- the first node in the list will
      //		be the node closest to the original node/NodeList.
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // query:
      //		a CSS selector.
      // returns:
      //		all sibling elements that come before the nodes in this dojo/NodeList.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red prev">Red One</div>
      // 	|		Some Text
      // 	|		<div class="blue prev">Blue One</div>
      // 	|		<div class="red second">Red Two</div>
      // 	|		<div class="blue">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".second").prevAll();
      //	|	});
      //		returns the two divs with class of "prev".
      //		Running this code:
      //	|		query(".first").prevAll(".red");
      //		returns the one div with class "red prev" and innerHTML "Red One".
      return this._getRelatedUniqueNodes(query, function (node, ary) {
        var pary = [];
        var prev = node;
        while (prev = prev.previousSibling) {
          if (prev.nodeType == 1) {
            pary.push(prev);
          }
        }
        return pary;
      }); // dojo/NodeList
    },
    andSelf: function () {
      // summary:
      //		Adds the nodes from the previous dojo/NodeList to the current dojo/NodeList.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red prev">Red One</div>
      // 	|		Some Text
      // 	|		<div class="blue prev">Blue One</div>
      // 	|		<div class="red second">Red Two</div>
      // 	|		<div class="blue">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".second").prevAll().andSelf();
      //	|	});
      //		returns the two divs with class of "prev", as well as the div with class "second".
      return this.concat(this._parent); // dojo/NodeList
    },
    //Alternate methods for the :first/:last/:even/:odd pseudos.
    first: function () {
      // summary:
      //		Returns the first node in this dojo/NodeList as a dojo/NodeList.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // returns:
      //		the first node in this dojo/NodeList
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red">Red One</div>
      // 	|		<div class="blue first">Blue One</div>
      // 	|		<div class="red">Red Two</div>
      // 	|		<div class="blue last">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".blue").first();
      //	|	});
      //		returns the div with class "blue" and "first".
      return this._wrap(this[0] && [this[0]] || [], this); // dojo/NodeList
    },
    last: function () {
      // summary:
      //		Returns the last node in this dojo/NodeList as a dojo/NodeList.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // returns:
      //		the last node in this dojo/NodeList
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="red">Red One</div>
      // 	|		<div class="blue first">Blue One</div>
      // 	|		<div class="red">Red Two</div>
      // 	|		<div class="blue last">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|	query(".blue").last();
      //	|	});
      //		returns the last div with class "blue",
      return this._wrap(this.length ? [this[this.length - 1]] : [], this); // dojo/NodeList
    },
    even: function () {
      // summary:
      //		Returns the even nodes in this dojo/NodeList as a dojo/NodeList.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // returns:
      //		the even nodes in this dojo/NodeList
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="interior red">Red One</div>
      // 	|		<div class="interior blue">Blue One</div>
      // 	|		<div class="interior red">Red Two</div>
      // 	|		<div class="interior blue">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".interior").even();
      //	|	});
      //		returns the two divs with class "blue"
      return this.filter(function (item, i) {
        return i % 2 != 0;
      }); // dojo/NodeList
    },
    odd: function () {
      // summary:
      //		Returns the odd nodes in this dojo/NodeList as a dojo/NodeList.
      // description:
      //		.end() can be used on the returned dojo/NodeList to get back to the
      //		original dojo/NodeList.
      // returns:
      //		the odd nodes in this dojo/NodeList
      // example:
      //		assume a DOM created by this markup:
      //	|	<div class="container">
      // 	|		<div class="interior red">Red One</div>
      // 	|		<div class="interior blue">Blue One</div>
      // 	|		<div class="interior red">Red Two</div>
      // 	|		<div class="interior blue">Blue Two</div>
      //	|	</div>
      //		Running this code:
      //	|	require(["dojo/query", "dojo/NodeList-traverse"
      //	|	], function(query){
      //	|		query(".interior").odd();
      //	|	});
      //		returns the two divs with class "red"
      return this.filter(function (item, i) {
        return i % 2 == 0;
      }); // dojo/NodeList
    }
  });
  return NodeList;
});